:root {
    /* principal color */
    --primaryColor: #c6b18a;
    --secondaryColor: #8a6e3a;
    --supplementColor: #c79c4b;

    --disabledColor: #7f7f7f;
    --whiteColor: #030239;
    --fillInput: #131313;
    --hoverColor: rgba(255, 255, 255, 0.1);

    /* background */
    --backgroundColor: #050606;

    /* Transparent Color */
    --whiteColorTransparent: rgba(255, 255, 255, 0.05);
    --darkColorTransparent: rgba(255, 255, 255, 0.2);
    --scrollColor: rgba(255, 255, 255, 0.368);

    /* Font color */
    --darkFontColor: #ffffff;
    --lightFontColor: #222e43;

    /* Success color */
    --succesColor: #1b9a22;
    --darksuccesColor: #26822b;

    /* Warning color */
    --warningColor: #FEA23C;
    --darkwarningColor: rgb(196, 111, 0);

    /* Danger color */
    --dangerColor: #df0b0b;
    --darkdangerColor: #ff2600;

    /* linear color */
    --linearColor: linear-gradient(to right, #c6b18a 0%, #c7a15a 100%);

    /* Supplement color */
    --color1: #FFB000;
    --color2: #D30074;
    --color3: #e96e16;
    --color4: #06b91e;

    /* For blur background */
    --blurcolor1: #cb8df5;
    --blurcolor2: #63adeb;
    --blurcolor3: #99e29a;

    /* Font family */
    // font-family: 'Epilogue', sans-serif;
    --DefaultFont: Poppins;
    --TitleFont: Poppins;
}
