/* Eds Normalize */
@use '../abstracts/variables' as *;
@use '../parameters/_mixins' as *;
@use '../parameters/_function' as *;

* {
	@include default_normalize;
	&::after {
		@include default_normalize;
	}
	&::before {
		@include default_normalize;
	}
	&::selection {
		background-color: var(--primaryColor);
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--scrollColor);
		border-radius: 4px;
		opacity: 0.1;
	}
	&::-webkit-scrollbar-corner {
		border-radius: 20px;
	}
	&::-webkit-scrollbar {
		padding: 20px 0px;
		background: var(--fillInput);
		width: 6px;
		&:hover {
			background: var(--fillInput);
			background: var(--fillInput);
		}
	}
}

.section-container {
	margin-top: 100px;
}


body {
	background-color: var(--backgroundColor);
	overflow-x:hidden;
	background-size: cover;
	font-family: "Poppins", sans-serif;
	color: var(--darkFontColor);
	// cursor: url("../../branding/img/CURSOR.png"), auto;
	// cursor: hidden;
}

.linear-text{
	background-image: var(--linearColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

body.modal {
	overflow: hidden;
}
body.modal.Large {
	overflow: hidden;
}

// Mixins input-form 
@mixin input-default {
	border: 1px solid var(--whiteColor);
    outline: none;
    background-color: var(--whiteColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 30px;
	margin-bottom: 15px;
    width: 100%;
    transition: all ease-in-out .4s;

    &:hover, &:focus {
        border: 1px solid var(--primaryColor) !important;
    }

	&.error {
		box-shadow: 5px 5px 4px rgba(245, 21, 21, 0.5);
	}
}

@mixin dropdown-default {
	position: absolute;
	margin-top: 0px;
	z-index: 200;
	padding: 10px;
	width: 100%;
	display: none;
	opacity: 0;
	border-radius: 20px;
	flex-direction: column;
	background-color: #fff;
	transition: all .5s ease-in-out;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

// Classe pour les Input login
.input-control {
	border: 1px solid var(--whiteColor);
    outline: none;
    background-color: var(--whiteColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 10px 30px;
    width: 100%;
    transition: all ease-in-out .4s;
    text-align: center;

    &::placeholder {
        text-align: center;
    }

    &:hover, &:focus {
        border: 1px solid var(--primaryColor) !important;
    }

	&.error {
		box-shadow: 5px 5px 4px rgba(245, 21, 21, 0.5);
	}
}

// Classe pour les Input
.input-form {    
	@include input-default;
	height: 50px;
}


// Dropdown bouton
.dropdown-button {
	width: 100%;
	position: relative;
	height: 50px !important;
	margin-bottom: 10px;
	
	.dropdown-body {
		font-family: "Calibri";
	}

	.dropdown-title {
		border: 1px solid var(--whiteColor);
		outline: none;
		height: 100%;
		background-color: var(--whiteColor);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 20px;
		padding: 10px 30px;
		margin-bottom: 15px;
		width: 100%;
		transition: all ease-in-out .4s;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: gray;
		cursor: pointer;
	
		&:hover, &:focus {
			border: 1px solid var(--primaryColor) !important;
		}
	}

	.selected{
		color: #000;
	}

	.dropdown-list {
		@include dropdown-default
	}

	.dropdown-icon {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.dropdown-item {
		cursor: pointer;
		padding: 5px 15px;
		margin: 5px;
		border-radius: 5px;

		&:hover {
			background-color: #d8d8d8;
		}
	}

	&.open .fa-sort-desc{
		transform: rotate(180deg);
	}

	&.open .dropdown-list {
		display: flex;
		opacity: 1;
	}
}

.table-container {
	width: 100%;
	align-items: center;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

	.empty-commande {
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.text {
		text-align: center;
	}
}

// Class pour les texte
.gray-text {
    color: gray;
}

.text-danger {
    color: red;
}

.text-center {
    text-align: center;
}

// Classs pour les boutons
.btn-submit {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--whiteColor);
	background-color: var(--primaryColor);	
	border-radius: 20px;
	padding: 10px 30px;
	width: 100%;
	font-weight: bold;
	color: #c6b18a;
	cursor: pointer;
    transition: all ease-in-out .4s;
	
	&:hover {
		background-color: var(--darkColorTransparent) !important;
	}
}

// Classs pour les boutons
.btn-success {
	color: var(--whiteColor);
	background-color: var(--secondaryColor);	
	border-radius: 20px;
	padding: 10px 30px;
	width: 100%;
	font-weight: bold;
	cursor: pointer;
    transition: all ease-in-out .4s;
	
	&:hover {
		background-color: var(--greyColor) !important;
		color: var(---darkColor)
	}
}

.fw-bold {
	font-weight: 600;
}

@mixin popover-header {
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
}

@mixin popover-body {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.popover {
	position: absolute;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	transition: all .5s ease-in-out;
	background: transparent;

	&.show{
		visibility: visible;
		opacity: 1;
	}

	&.popover-error {
		font-size: 0.8rem;
		width: 190px;
		margin-left: 40px;
		margin-top: -20px;
	
		.popover-header {
			@include popover-header;
			width: 100%;
			background-color: #f03535;
			padding: 5px 20px;
			font-weight: 600;
		}
		.popover-body {
			@include popover-body;
			width: 100%;
			background-color: rgba(#f03535, .8);
			padding: 5px 20px;
		}
	}

	&.popover-info {
		color: #000;
		font-size: 0.8rem;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		background: #fff;
		margin-top: 50px;

		.popover-header {
			@include popover-header;
			width: 100%;
			padding: 5px 20px;
			font-weight: 600;
		}
		.popover-body {
			@include popover-body;
			width: 100%;
			padding: 5px 20px;
		}
	}
}

.ellipsis {  
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}